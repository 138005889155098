// src/pages/newDonation.tsx

import React, { useEffect, useState } from "react";
import layoutTwo from "../components/layout/layoutTwo";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { addDonation } from "../redux/slices/addDonationSlice";
import { getDonationOptions } from "../redux/slices/options/getDonationOptionsSlice";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import Grid from "@mui/material/Unstable_Grid2";
import { emphasize, styled } from "@mui/material/styles";
import { Icon } from "@iconify/react";
import {
  Breadcrumbs,
  Chip,
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
  Paper,
  Autocomplete,
  Container,
} from "@mui/material";

const today = dayjs();

interface Errors {
  [key: string]: string;
}

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

function handleClick(event: React.MouseEvent<Element, MouseEvent>) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
  // Navigate to the clicked breadcrumb
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(10),
  ...theme.typography.body2,
  textAlign: "center",
  borderRadius: 14,
}));

interface DonationData {
  name: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
  cash_chq_receive_date: string;
  amount: string;
  payment_mode: string;
  purpose: string;
  event: string;
  tr_no: string;
  head: string;
  pan: string;
  email: string;
  whatsapp_group: string;
  cash_deposit_date: string;
  gift: string;
  note: string;
}

const NewDonation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { donation } = useSelector((state: any) => state.addDonation);
  const { options, loadingOptions } = useSelector(
    (state: any) => state.getDonationOptions
  );
  const [numericAmount, setNumericAmount] = useState<number | null>(null);
  const [value, setValue] = React.useState<Dayjs | null>(null);

  const [click, setClick] = useState(false);

  useEffect(() => {
    dispatch(getDonationOptions());
  }, []);

  useEffect(() => {
    if (donation && click) navigate("/donations");
  }, [donation]);

  const [data, setData] = useState<DonationData>({
    name: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    cash_chq_receive_date: "",
    amount: "",
    payment_mode: "",
    purpose: "",
    event: "",
    tr_no: "",
    head: "",
    pan: "",
    email: "",
    whatsapp_group: "",
    cash_deposit_date: "",
    gift: "",
    note: "",
  });

  const [paymentMode, setPaymentMode] = useState(""); // Track the selected payment mode

  const [errors, setErrors] = useState<Errors>({});

  // const textHandler = (e: any, input: any) => {
  //   setData({
  //     ...data,
  //     [input]: e.target.value, // Keep it as a string for validation purposes
  //   });
  //   console.log("Updated Data:", value);
  // };

  const handleKeyDown = (e: any) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "+", // Plus sign for country codes
    ];

    const input = e.target as HTMLInputElement;

    // If the first character is being typed, restrict it to `+` or `1-9`
    if (
      input.value.length === 0 &&
      (e.key < "1" || e.key > "9") &&
      e.key !== "+"
    ) {
      e.preventDefault();
      return;
    }

    // If length is 15 and the user is not trying to delete or navigate, prevent input
    if (input.value.length >= 15 && !allowedKeys.includes(e.key)) {
      e.preventDefault();
      return;
    }

    // Allow only digits and special keys for the rest of the input
    if (
      !allowedKeys.includes(e.key) && // Allow special keys
      (e.key < "0" || e.key > "9") // Allow digits 0-9 for the rest
    ) {
      e.preventDefault(); // Block all other keys
    }
  };

  const textHandler = (e: any, input: keyof DonationData) => {
    let newValue = e.target.value;

    // Phone number validation: Allow only digits (0-9) and optionally a '+' at the beginning for country codes
    if (input === "phone") {
      // Remove invalid characters, allow only digits (0-9) and optionally the '+' at the beginning
      newValue = newValue.replace(/[^0-9+]/g, ""); // Remove any characters that are not 0-9 or '+'

      // Ensure '+' is only allowed as the first character
      if (newValue.indexOf("+") > 0) {
        newValue = newValue.replace("+", ""); // Remove any '+' that isn't the first character
      }

      // Ensure the first character is not `0`
      if (newValue.startsWith("0")) {
        newValue = newValue.substring(1); // Remove leading 0
      }

      // Trim the value to a maximum of 15 characters
      newValue = newValue.substring(0, 15);

      setData((prevData) => ({
        ...prevData,
        [input]: newValue,
      }));

      return; // Return early as phone is handled
    }

    setData((prevData) => ({
      ...prevData,
      [input]: newValue,
    }));
  };

  const dateHandler = (e: Dayjs | null, input: keyof DonationData) => {
    const selectedDate = e ? e.toISOString() : dayjs().toISOString(); // Store the selected date in ISO format

    setData((prevData) => ({
      ...prevData,
      [input]: selectedDate, // Always update the selected date in the state
      // Only sync cash_deposit_date with cash_chq_receive_date if payment mode is "Online"
      cash_deposit_date:
        paymentMode === "Online" && input === "cash_chq_receive_date"
          ? selectedDate // Sync deposit date if payment mode is "Online"
          : input === "cash_deposit_date"
          ? selectedDate // Allow updating deposit date if not Online
          : prevData.cash_deposit_date,
    }));
  };

  const autoHandler = (e: any, value: any, input: keyof DonationData) => {
    const selectedValue = value && typeof value === "string" ? value : "";

    setData((prevData) => ({
      ...prevData,
      [input]: selectedValue,
    }));

    if (input === "payment_mode") {
      setPaymentMode(selectedValue); // Update payment mode

      // Reset deposit date if payment mode is not "Online"

      setData((prevData) => ({
        ...prevData,
        cash_deposit_date: data.cash_chq_receive_date, // Allow user to set deposit date manually
      }));
    }
  };

  console.log("data", data);
  const validateData = (): boolean => {
    let tempErrors: Errors = {};
    let isValid = true;

    // Name validation: Mandatory field
    if (!data.name.trim()) {
      isValid = false;
      tempErrors.name = "Name is required.";
      console.log("FALSE HERE");
    }
    // else if (!/^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(data.name)) {
    //   isValid = false;
    //   tempErrors.name = "Invalid name format.";
    //   console.log("FALSE HERE");
    // }

    // Phone number validation
    const phoneRegex = /^\+?[1-9][0-9]{9,14}$/; // Allows optional '+' followed by 10-15 digits, first digit can't be 0
    if (!data.phone.trim()) {
      isValid = false;
      tempErrors.phone = "Phone number is required.";
    } else if (!phoneRegex.test(data.phone)) {
      isValid = false;
      tempErrors.phone =
        "Invalid phone number. It must be 10-15 digits, and the first digit cannot be 0.";
    }

    // Amount validation: Mandatory field
    if (!data.amount.trim()) {
      isValid = false;
      tempErrors.amount = "Amount is required.";
      console.log("FALSE HERE");
    } else if (!/^\d+(\.\d{1,2})?$/.test(data.amount.trim())) {
      isValid = false;
      tempErrors.amount = "Invalid amount format.";
      console.log("FALSE HERE");
    } else {
      // Store the numeric value in a separate state variable
      setNumericAmount(parseFloat(data.amount.trim()));
    }

    // Transaction Date validation: Mandatory field
    if (!data.cash_chq_receive_date) {
      isValid = false;
      tempErrors.cash_chq_receive_date = "Transaction date is required.";
      console.log("FALSE HERE");
    }

    // // Payment Mode validation: Mandatory field
    // if (!data.payment_mode.trim()) {
    //   isValid = false;
    //   tempErrors.payment_mode = "Payment mode is required.";
    //   console.log("FALSE HERE");
    // }

    // Pincode validation: Optional field, validate if present
    if (data.pincode.trim() && !/^\d{6}$/.test(data.pincode)) {
      isValid = false;
      tempErrors.pincode = "Invalid pincode format.";
      console.log("FALSE HERE");
    }

    // PAN validation: Optional field, validate if present
    if (data.pan.trim() && !/^[A-Z]{5}[0-9]{4}[A-Z]$/.test(data.pan)) {
      isValid = false;
      tempErrors.pan = "Invalid PAN number format.";
      console.log("FALSE HERE");
    }

    // Email validation: Optional field, validate if present
    if (
      data.email.trim() &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email)
    ) {
      isValid = false;
      tempErrors.email = "Invalid email format.";
      console.log("FALSE HERE");
    }

    // Tax Receipt No. validation: Optional field, validate if present
    if (data.tr_no.trim() && !/^\d+$/.test(data.tr_no)) {
      isValid = false;
      tempErrors.tr_no = "Tax Receipt No. must contain only numbers.";
      console.log("FALSE HERE");
    }

    // Set the collected errors
    setErrors(tempErrors);

    // Return the overall validity of the form data
    return isValid;
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isFormDataValid = validateData();

    if (isFormDataValid) {
      // Since dates are already stored in ISO format, send them as-is
      const submitData = {
        ...data,
        amount: numericAmount,
        cash_chq_receive_date:
          data.cash_chq_receive_date || dayjs().toISOString(), // Default to current date in ISO format
        cash_deposit_date: data.cash_deposit_date || dayjs().toISOString(), // Default to current date in ISO format
      };

      dispatch(addDonation(submitData));
      console.log("Data Submitted:", submitData);
      setClick(true);
    } else {
      console.log("Validation Errors:", errors);
      alert("There are errors in the form. Please fix them before submitting.");
    }
  };

  // const submitHandler = () => {
  //   setClick(true);
  //   dispatch(createUser(data));
  //   console.log("Data Submitted", data);
  // };

  return (
    <div className="user-create">
      <div role="presentation" onClick={handleClick}>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Home"
            icon={<Icon icon="lucide:home" className="icons" />}
          />
          <StyledBreadcrumb
            component="a"
            href="/donations"
            label="Donations"
            onClick={() => navigate("/donations")}
          />
          <StyledBreadcrumb label="New" />
        </Breadcrumbs>
      </div>
      <Container maxWidth="md" sx={{ marginTop: 5 }}>
        {loadingOptions ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "70vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <StyledPaper variant="outlined">
            <Box
              component="form"
              sx={{
                flexGrow: 1,
              }}
              noValidate
              autoComplete="off"
              onSubmit={submitHandler}
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 4, md: 4 }}
              >
                <Grid xs={12} sm={12}>
                  <Typography variant="h4" gutterBottom>
                    Add new donation
                  </Typography>
                </Grid>
                <Grid xs={12} sm={6}>
                  <TextField
                    label="Name"
                    size="small"
                    variant="outlined"
                    required // Name is mandatory
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name}
                    onChange={(e) => textHandler(e, "name")}
                  />
                </Grid>

                <Grid xs={12} sm={6}>
                  <TextField
                    label="Phone"
                    size="small"
                    variant="outlined"
                    required // Phone is mandatory
                    fullWidth
                    error={!!errors.phone}
                    helperText={errors.phone}
                    onKeyDown={handleKeyDown} // Block invalid key presses and more than 15 characters
                    onChange={(e) => textHandler(e, "phone")} // Sanitize input on change and enforce max length
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      value={
                        data.cash_chq_receive_date
                          ? dayjs(data.cash_chq_receive_date)
                          : dayjs()
                      }
                      label="Transaction Date"
                      slotProps={{
                        textField: {
                          size: "small",
                          required: true,
                          error: !!errors.cash_chq_receive_date,
                          helperText: errors.cash_chq_receive_date,
                        },
                      }}
                      minDate={dayjs("2024-01-01T00:00:00.000")}
                      views={["year", "month", "day"]}
                      onChange={(e) => dateHandler(e, "cash_chq_receive_date")}
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Amount"
                    size="small"
                    variant="outlined"
                    required // Amount is mandatory
                    fullWidth
                    error={!!errors.amount}
                    helperText={errors.amount}
                    onChange={(e) => textHandler(e, "amount")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="payment-mode-autocomplete"
                    options={paymentModes}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    onChange={(e, value) =>
                      autoHandler(e, value, "payment_mode")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Payment Mode"
                        size="small"
                        required // Payment Mode is mandatory
                        error={!!errors.payment_mode}
                        helperText={errors.payment_mode}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="outlined-basic"
                    options={options?.purpose.options}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    onChange={(e: any, value: any) =>
                      autoHandler(e, value, "purpose")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Purpose"
                        size="small"
                        error={!!errors.purpose}
                        helperText={errors.purpose}
                      />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={12}>
                  <TextField
                    label="Address"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.address}
                    helperText={errors.address}
                    onChange={(e) => textHandler(e, "address")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="City"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.city}
                    helperText={errors.city}
                    onChange={(e) => textHandler(e, "city")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="state-autocomplete"
                    options={states}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    onChange={(e: any, value: any) =>
                      autoHandler(e, value, "state")
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="State" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Pincode"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.pincode}
                    helperText={errors.pincode}
                    onChange={(e) => textHandler(e, "pincode")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="PAN"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.pan}
                    helperText={errors.pan}
                    onChange={(e) => textHandler(e, "pan")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="outlined-basic"
                    options={options?.events.options}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    onChange={(e: any, value: any) =>
                      autoHandler(e, value, "event")
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Event" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Tax Receipt No."
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.tr_no}
                    helperText={errors.tr_no}
                    onChange={(e) => textHandler(e, "tr_no")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="outlined-basic"
                    options={options?.heads.options}
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    onChange={(e: any, value: any) =>
                      autoHandler(e, value, "head")
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Head" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <TextField
                    label="Email"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email}
                    onChange={(e) => textHandler(e, "email")}
                  />
                </Grid>

                <Grid xs={12} md={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      // Show selected deposit date or allow user to select a date
                      value={
                        paymentMode === "Online"
                          ? data.cash_chq_receive_date
                            ? dayjs(data.cash_chq_receive_date)
                            : null
                          : data.cash_deposit_date
                          ? dayjs(data.cash_deposit_date)
                          : null
                      }
                      label="Deposit Date"
                      disabled={paymentMode === "Online"} // Disable if payment mode is "Online"
                      slotProps={{
                        textField: {
                          size: "small",
                          error: !!errors.cash_deposit_date,
                          helperText: errors.cash_deposit_date,
                        },
                      }}
                      onChange={(e) => dateHandler(e, "cash_deposit_date")} // Ensure deposit date is updated
                    />
                  </LocalizationProvider>
                </Grid>

                <Grid xs={12} md={6}>
                  <Autocomplete
                    disablePortal
                    id="outlined-basic"
                    options={options?.gifts?.options || []} // Ensure options are an array
                    clearOnEscape
                    disableClearable
                    autoHighlight
                    onChange={(e, value) =>
                      autoHandler(e, value as string, "gift")
                    } // Ensure value is cast to string
                    renderInput={(params) => (
                      <TextField {...params} label="Gift" size="small" />
                    )}
                  />
                </Grid>

                <Grid xs={12} md={12}>
                  <TextField
                    label="Note"
                    size="small"
                    variant="outlined"
                    fullWidth
                    error={!!errors.note}
                    helperText={errors.note}
                    onChange={(e) => textHandler(e, "note")}
                  />
                </Grid>

                <Grid xs={12} md={12}>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ width: 200, mt: 2 }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
        )}
      </Container>
    </div>
  );
};

export default layoutTwo(NewDonation);

const paymentModes = ["Cash", "Cheque", "Online"];

const states = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Delhi",
  "Jammu and Kashmir",
  "Ladakh",
  "Lakshadweep",
  "Puducherry",
];
