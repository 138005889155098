import React, { useEffect, useState } from "react";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { Button, styled, IconButton } from "@mui/material";
import theme from "../../theme";
import { Avatar, Stack, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { group } from "console";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useAppDispatch } from "../../redux/hooks";
import { getDonationData } from "../../redux/slices/getDonationDataSlice";

const headerTextStyle = {
  color: "#121619",
  fontSize: "14px",
  fontWeight: "500",
};

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const TableBody = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { donationData, loading } = useSelector(
    (state: any) => state.getDonations
  );

  const [data, setData] = useState<any>([]);

  useEffect(() => {
    if (donationData) {
      console.log("Effect Data - ", donationData);
      let data1 = donationData?.map((donation: any, index: any) => ({
        id: donation.sr_no,
        name: donation.name,
        phone: donation.phone,
        address: donation.address,
        city: donation.city,
        state: donation.state,
        pincode: donation.pincode,
        txnDate: donation.cash_chq_receive_date,
        amount: donation.amount,
        paymentMode: donation.payment_mode,
        purpose: donation.purpose,
        event: donation.event,
        trNo: donation.tr_no,
        head: donation.head,
        pan: donation.pan,
        email: donation.email,
        depositDate: donation.cash_deposit_date,
        gift: donation.gift,
        status: donation.status,
        note: donation.note,
        whatsappGroup: donation?.whatsapp_group,
      }));
      setData(data1);
    }
  }, [donationData]);

  console.log("donationData", donationData);

  const handleEdit = (id: number) => {
    navigate(`/donations/edit/${id}`); // Navigate to the edit page
  };

  const refreshData = () => {
    dispatch(getDonationData());
  };

  const columns: any = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 100,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Sr. No.</p>
        </Stack>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 170,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Name</p>
        </Stack>
      ),
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 150,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Phone</p>
        </Stack>
      ),
    },
    {
      field: "address",
      headerName: "Address",
      width: 300,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Address</p>
        </Stack>
      ),
    },
    {
      field: "city",
      headerName: "City",
      width: 150,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>City</p>
        </Stack>
      ),
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>State</p>
        </Stack>
      ),
    },
    {
      field: "pincode",
      headerName: "Pincode",
      width: 150,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Pincode</p>
        </Stack>
      ),
    },
    {
      field: "txnDate",
      headerName: "Txn Date",
      width: 150,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Txn Date</p>
        </Stack>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Amount</p>
        </Stack>
      ),
    },
    {
      field: "paymentMode",
      headerName: "Payment Mode",
      width: 200,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Payment Mode</p>
        </Stack>
      ),
    },
    {
      field: "purpose",
      headerName: "Purpose",
      width: 200,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Purpose</p>
        </Stack>
      ),
    },
    {
      field: "event",
      headerName: "Event",
      width: 200,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Event</p>
        </Stack>
      ),
    },
    {
      field: "trNo",
      headerName: "TR No.",
      width: 200,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>TR No.</p>
        </Stack>
      ),
    },
    {
      field: "head",
      headerName: "head",
      width: 200,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Head</p>
        </Stack>
      ),
    },
    {
      field: "pan",
      headerName: "PAN",
      width: 200,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>PAN</p>
        </Stack>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Email</p>
        </Stack>
      ),
    },
    {
      field: "depositDate",
      headerName: "Deposit Date",
      width: 200,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Deposit Date</p>
        </Stack>
      ),
    },
    {
      field: "gift",
      headerName: "Gift",
      width: 150,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Gift</p>
        </Stack>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Status</p>
        </Stack>
      ),
    },
    {
      field: "whatsappGroup",
      headerName: "Whatsapp Group",
      width: 150,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Whatsapp Group</p>
        </Stack>
      ),
    },
    {
      field: "note",
      headerName: "Note",
      width: 250,
      renderHeader: () => (
        <Stack direction="row" alignItems="left">
          <p style={headerTextStyle}>Note</p>
        </Stack>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 70,

      renderCell: (params: any) => (
        <IconButton aria-label="edit" onClick={() => handleEdit(params.row.id)}>
          <Icon icon="lucide:pencil" className="icons" />
        </IconButton>
      ),
    },
  ];

  return (
    <div className="table">
      <div className="button">
        <Button variant="outlined" sx={{ mr: 2}} startIcon={<Icon icon="lucide:refresh-cw" className="icons" />} onClick={refreshData}>
          Refresh
        </Button>
        <Button variant="contained" startIcon={<Icon icon="lucide:plus" className="icons" />} onClick={() => navigate("/donations/new")}>
          Create
        </Button>
      </div>
      <Box
        sx={{
          width: "100%",
          marginTop: "10px",
          marginBottom: "50px",
        }}
      >
        <DataGridPro
          rows={data}
          columns={columns}
          loading={loading}
          autoHeight
          {...data}
          slots={{ toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay }}
          sx={{ "--DataGrid-overlayHeight": "300px" }}
          checkboxSelection
          initialState={{
            pinnedColumns: { left: ["name"], right: ["actions"] },
          }}
        />
      </Box>
    </div>
  );
};

export default TableBody;